import HomePage from "pages/home";
import { Route, Routes } from "react-router-dom";

function App() {

  let ColorScheme = "dark"

  return (
    <div data-testid="app" className={`h-screen overflow-y-scroll ${ColorScheme === "dark" ? "dark" : "light"}`}>
      <Routes>
        <Route path="*" element={<HomePage/>}/>
      </Routes>
    </div>
  );
}

export default App;