import React from "react";
import Sandbox from "widgets/components/sandbox";

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-primary font-sans flex flex-col justify-between relative overflow-hidden">
      <main className="container mx-auto px-4 py-20 relative z-10 flex-grow flex items-center">
        <div className="flex flex-col items-center justify-center gap-16 w-full">
          <div className="space-y-4 text-center">
            <h1 className="text-4xl font-thin tracking-wider font-martian
              text-[#666] relative select-none
              before:content-['CORTEXY'] before:absolute before:inset-0
              before:text-[#555] before:-translate-y-[1px]
              after:content-['CORTEXY'] after:absolute after:inset-0
              after:text-[#777] after:translate-y-[1px]">
              CORTEXY
            </h1>
            <p className="text-accent/90 text-xs font-martian font-thin tracking-wider max-w-md mx-auto">
              A completely new neural network operating on the principles of physiological neurons and biochemical processes
            </p>
          </div>
          <div className="w-full max-w-5xl mx-auto">
            <Sandbox />
          </div>
        </div>
      </main>

      <footer className="container mx-auto px-4 py-6 flex justify-between items-center border-t border-accent/10">
        <div className="text-accent/80 font-martian text-xs font-thin tracking-wider">CORTEXY</div>
        <button 
          onClick={() => setIsModalOpen(true)}
          className="px-3 py-1.5 border border-accent/20 rounded text-xs text-accent/80 font-martian
            hover:bg-accent/5 transition-colors"
        >
          Contact Us
        </button>
      </footer>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-primary/90 p-8 rounded-lg relative border border-accent/20">
            <div className="space-y-6">
              <h2 className="text-accent/80 font-martian text-xs font-thin tracking-wider text-center">MORE INFO</h2>
              <div className="p-4 bg-black/20 rounded-lg border-2 border-accent/20">
                <img 
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://t.me/+EhAF--PJXR4xZTcy&color=B8A88A&bgcolor=000000`}
                  alt="Contact QR Code"
                  className="w-48 h-48 mx-auto opacity-80 hover:opacity-100 transition-opacity
                    border-8 border-accent/20 rounded-sm"
                />
              </div>
              <button 
                onClick={() => setIsModalOpen(false)}
                className="w-full text-accent/80 hover:text-accent font-martian text-xs font-thin tracking-wider
                  border border-accent/20 rounded py-2 hover:bg-accent/5 transition-colors"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;