import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const baseUrl = process.env.REACT_APP_ENV !== "mocks"
  ? window._env_?.API_HOST
  : window.location.origin;

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    return headers
  },
})

const baseQueryWithRetry = async (
  args: Parameters<typeof baseQuery>[0],
  api: Parameters<typeof baseQuery>[1],
  extraOptions: Parameters<typeof baseQuery>[2] & { maxRetries?: number }
) => {
  let result: ReturnType<typeof baseQuery>;
  let retries = extraOptions?.maxRetries ?? 5;

  do {
    result = await baseQuery(args, api, extraOptions);

    if (!result.error) {
      // Успешный результат - возвращаем сразу
      return result;
    }

    const error = result.error as FetchBaseQueryError;

    if (error?.status === 'FETCH_ERROR' || error?.status === 'TIMEOUT_ERROR') {
      retries -= 1;
    } else if (error?.status === "PARSING_ERROR") {
      // Ошибка парсинга - не повторяем запрос
      retries = 0;
    } else if (typeof error?.status === "number" && error.status >= 500) {
      // Ошибка сервера - уменьшаем счетчик попыток
      retries -= 1;
    } else {
      // Все остальные ошибки - не повторяем запрос
      retries = 0;
    }

  } while (retries > 0);

  // Возвращаем последний результат после всех попыток
  return result;
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "cortexy",
  /**
   * A bare-bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,

  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "Sandbox",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})