import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

interface NetworkInfo {
  layers: {
    name: string;
    neurons: number;
  }[];
  activator: {
    interval: number;
    strategy: string;
  };
}

interface NeuronState {
  id: number;
  activation: number;
  threshold: number;
  connections: number;
}

interface Point {
  id: string;
  x: number;
  y: number;
  networkInfo?: NetworkInfo;
  neuronStates?: NeuronState[];
}

interface AnimatedPoint extends Point {
  currentX: number;
  currentY: number;
  velocityX: number;
  velocityY: number;
}

interface ServerMessage {
  type: string;
  points: Point[];
}

const LERP_FACTOR = 0.1; // Фактор интерполяции
const VELOCITY_DAMPING = 0.95; // Затухание скорости

const NetworkStatus: React.FC<{points: Point[]}> = ({points}) => {
  const renderNeuronState = (active: boolean) => (
    <span className={`inline-block w-6 h-4 border border-accent/20 mx-0.5 
      ${active ? 'bg-accent/20' : ''}`}
    />
  );

  return (
    <div className="network-status mt-4">
      {points.map((point, index) => (
        <React.Fragment key={point.id}>
          {index > 0 && (
            <div className="my-8 w-full border-t border-accent/10" />
          )}
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="text-accent/60 text-xs font-martian">CORTEXY {point.id}</div>
              <div className="text-accent/80 text-xs">
                Position: {Math.round(point.x)}, {Math.round(point.y)}
              </div>
            </div>

            {point.networkInfo && (
              <>
                <div className="grid grid-cols-[120px,1fr] gap-4">
                  <span className="text-accent/60">ACTIVATOR</span>
                  <span className="text-accent/80">
                    {point.networkInfo.activator.strategy} ({point.networkInfo.activator.interval}ms)
                  </span>
                </div>

                <div className="grid grid-cols-[120px,1fr] gap-4">
                  <span className="text-accent/60">HIPPOCAMPUS</span>
                  <div className="flex items-center">
                    {Array.from({length: 4}, (_, i) => (
                      <span key={i} className="flex items-center">
                        {renderNeuronState(point.neuronStates?.some(n => n.id === i + 1 && n.activation > 0.5) || false)}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="grid grid-cols-[120px,1fr] gap-4">
                  <span className="text-accent/60">MOTOR</span>
                  <div className="flex items-center space-x-2">
                    <span className="flex items-center">
                      L {renderNeuronState(point.neuronStates?.some(n => n.id === 5 && n.activation > 0.5) || false)}
                    </span>
                    <span className="flex items-center">
                      R {renderNeuronState(point.neuronStates?.some(n => n.id === 6 && n.activation > 0.5) || false)}
                    </span>
                    <span className="flex items-center">
                      RL {renderNeuronState(point.neuronStates?.some(n => n.id === 7 && n.activation > 0.5) || false)}
                    </span>
                    <span className="flex items-center">
                      RR {renderNeuronState(point.neuronStates?.some(n => n.id === 8 && n.activation > 0.5) || false)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const Sandbox = () => {
  const renderNeuronState = (active: boolean) => (
    <span className={`inline-block w-6 h-4 border border-accent/20 mx-0.5 
      ${active ? 'bg-accent/20' : ''}`}
    />
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const animationFrameRef = useRef<number>();
  const [serverPoints, setServerPoints] = useState<Point[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);
  const animatedPointsRef = useRef<AnimatedPoint[]>([]);
  const [retryCount, setRetryCount] = useState(0);
  const retryCountRef = useRef(0);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const pingIntervalRef = useRef<NodeJS.Timeout>();
  const MAX_RETRIES = 5;
  const RETRY_INTERVAL = 3000; // 3 секунды

  // Функция линейной интерполяции
  const lerp = (start: number, end: number, factor: number) => {
    return start + (end - start) * factor;
  };

  // Обновление анимированных точек
  const updateAnimatedPoints = () => {
    animatedPointsRef.current = serverPoints.map(targetPoint => {
      const existingPoint = animatedPointsRef.current.find(p => p.id === targetPoint.id);
      
      if (!existingPoint) {
        return {
          ...targetPoint,
          currentX: targetPoint.x,
          currentY: targetPoint.y,
          velocityX: 0,
          velocityY: 0
        };
      }

      // Вычисляем желаемое изменение позиции
      const dx = targetPoint.x - existingPoint.currentX;
      const dy = targetPoint.y - existingPoint.currentY;

      // Обновляем скорость с учетом желаемого перемещения
      existingPoint.velocityX = (existingPoint.velocityX + dx * LERP_FACTOR) * VELOCITY_DAMPING;
      existingPoint.velocityY = (existingPoint.velocityY + dy * LERP_FACTOR) * VELOCITY_DAMPING;

      // Обновляем текущую позицию
      existingPoint.currentX += existingPoint.velocityX;
      existingPoint.currentY += existingPoint.velocityY;

      return existingPoint;
    });
  };

  // Функция для отрисовки точек
  const drawPoints = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    
    animatedPointsRef.current.forEach((point) => {
      ctx.beginPath();
      ctx.fillStyle = '#808080';
      ctx.arc(point.currentX, point.currentY, 5, 0, Math.PI * 2);
      ctx.fill();

      // Добавляем свечение
      ctx.beginPath();
      ctx.arc(point.currentX, point.currentY, 15, 0, Math.PI * 2);
      const gradient = ctx.createRadialGradient(
        point.currentX, point.currentY, 5,
        point.currentX, point.currentY, 15
      );
      gradient.addColorStop(0, 'rgba(128, 128, 128, 0.3)');
      gradient.addColorStop(1, 'rgba(128, 128, 128, 0)');
      ctx.fillStyle = gradient;
      ctx.fill();

      // Добавляем след
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(128, 128, 128, 0.1)';
      ctx.lineWidth = 2;
      ctx.moveTo(point.currentX, point.currentY);
      ctx.lineTo(
        point.currentX - point.velocityX * 5,
        point.currentY - point.velocityY * 5
      );
      ctx.stroke();
    });
  };

  // Функция для отрисовки спиннера
  const drawSpinner = (ctx: CanvasRenderingContext2D, time: number) => {
    const centerX = ctx.canvas.width / 2;
    const centerY = ctx.canvas.height / 2;
    const radius = 30;
    
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(128, 128, 128, 0.2)';
    ctx.lineWidth = 4;
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.stroke();
    
    ctx.beginPath();
    ctx.strokeStyle = '#808080';
    ctx.lineWidth = 4;
    ctx.arc(
      centerX,
      centerY,
      radius,
      (-Math.PI / 2) + time,
      (-Math.PI / 2) + time + Math.PI / 2
    );
    ctx.stroke();
  };

  const cleanupConnection = () => {
    if (wsRef.current) {
      // Удаляем все обработчики перед закрытием
      wsRef.current.onclose = null;
      wsRef.current.onerror = null;
      wsRef.current.onmessage = null;
      wsRef.current.onopen = null;
      
      wsRef.current.close();
      wsRef.current = null;
    }

    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = undefined;
    }

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = undefined;
    }
  };

  const startPingInterval = (ws: WebSocket) => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }
    
    pingIntervalRef.current = setInterval(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "ping" }));
      }
    }, 30000);
  };

  const connectWebSocket = () => {
    if (isConnecting || (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING)) {
      console.log('Connection attempt already in progress');
      return;
    }

    setIsConnecting(true);
    cleanupConnection();

    try {
      const ws = new WebSocket(window._env_.REACT_APP_WS_URL);
      wsRef.current = ws;

      ws.onopen = () => {
        console.log('WebSocket Connected');
        setIsConnected(true);
        setIsConnecting(false);
        startPingInterval(ws);
      };

      ws.onclose = (event) => {
        console.log('WebSocket Disconnected:', event.code, event.reason);
        setIsConnected(false);
        setIsConnecting(false);
        cleanupConnection();

        // Планируем повторное подключение только если это не было намеренное закрытие
        if (event.code !== 1000) {
          reconnectTimeoutRef.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            connectWebSocket();
          }, RETRY_INTERVAL);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        // При ошибке соединения WebSocket автоматически вызовет onclose
      };

      ws.onmessage = (event) => {
        try {
          const message: ServerMessage = JSON.parse(event.data);
          if (message.type === 'update') {
            setServerPoints(message.points);
            // Инициализируем анимированные точки при первом получени
            if (animatedPointsRef.current.length === 0) {
              animatedPointsRef.current = message.points.map(point => ({
                ...point,
                currentX: point.x,
                currentY: point.y,
                velocityX: 0,
                velocityY: 0
              }));
            }
          }
        } catch (e) {
          console.error('Failed to parse WebSocket message:', e);
        }
      };
    } catch (error) {
      console.error('Failed to create WebSocket:', error);
      setIsConnecting(false);
      
      // Планируем повторную попытку при ошибке создания соединения
      reconnectTimeoutRef.current = setTimeout(() => {
        connectWebSocket();
      }, RETRY_INTERVAL);
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      cleanupConnection();
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const resizeCanvas = () => {
      canvas.width = 500;  // Фиксированная ширина
      canvas.height = 500; // Фиксированная высота
      console.log('Canvas size:', canvas.width, canvas.height);
    };
    resizeCanvas();

    let time = 0;
    const animate = () => {
      time += 0.05;
      
      if (!isConnected) {
        drawSpinner(ctx, time);
      } else {
        console.log('Drawing points:', animatedPointsRef.current.length);
        updateAnimatedPoints();
        drawPoints(ctx);
      }
      
      animationFrameRef.current = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isConnected, serverPoints]);

  // Обработчик клика по кавасу для выбора точки
  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Находим ближайшую точку
    const point = animatedPointsRef.current.reduce((closest, current) => {
      const currentDist = Math.hypot(current.currentX - x, current.currentY - y);
      const closestDist = closest ? Math.hypot(closest.currentX - x, closest.currentY - y) : Infinity;
      return currentDist < closestDist ? current : closest;
    }, null as AnimatedPoint | null);

    // Если точка найдена и достаточно близко (в пределах 20px)
    if (point && Math.hypot(point.currentX - x, point.currentY - y) < 20) {
      const serverPoint = serverPoints.find(p => p.id === point.id);
      setSelectedPoint(serverPoint || null);
    } else {
      setSelectedPoint(null);
    }
  };

  return (
    <div className="sandbox-container">
      <div className="terminal">
        <canvas 
          ref={canvasRef} 
          className="terminal-canvas"
          onClick={handleCanvasClick}
          style={{ width: '500px', height: '500px' }}
        />
      </div>
      <div className="network-status">
        {serverPoints.map((point, index) => (
          <React.Fragment key={point.id}>
            {index > 0 && (
              <div className="my-8 w-full border-t border-accent/10" />
            )}
            <div className="space-y-2 font-mono text-xs">
              <div className="flex">
                <span className="text-accent/60 mr-2">$</span>
                <span className="text-accent/80">CORTEXY {point.id}</span>
              </div>

              <div className="flex">
                <span className="text-accent/60 mr-2">{'>'}</span>
                <span className="text-accent/80">POS: [{Math.round(point.x).toString().padStart(3, ' ')}, {Math.round(point.y).toString().padStart(3, ' ')}]</span>
              </div>

              {point.networkInfo && (
                <>
                  <div className="flex">
                    <span className="text-accent/60 mr-2">{'>'}</span>
                    <span className="text-accent/80">ACT: {point.networkInfo.activator.strategy} [{point.networkInfo.activator.interval}ms]</span>
                  </div>

                  <div className="flex">
                    <span className="text-accent/60 mr-2">{'>'}</span>
                    <span className="text-accent/80">HIP: {Array.from({length: 4}, (_, i) => 
                      point.neuronStates?.some(n => n.id === i + 1 && n.activation > 0.5) ? '[X]' : '[ ]'
                    ).join(' ')}</span>
                  </div>

                  <div className="flex">
                    <span className="text-accent/60 mr-2">{'>'}</span>
                    <span className="text-accent/80">MOT: L{point.neuronStates?.some(n => n.id === 5 && n.activation > 0.5) ? '[X]' : '[ ]'} 
                      R{point.neuronStates?.some(n => n.id === 6 && n.activation > 0.5) ? '[X]' : '[ ]'} 
                      RL{point.neuronStates?.some(n => n.id === 7 && n.activation > 0.5) ? '[X]' : '[ ]'} 
                      RR{point.neuronStates?.some(n => n.id === 8 && n.activation > 0.5) ? '[X]' : '[ ]'}</span>
                  </div>

                  <div className="flex">
                    <span className="text-accent/60 mr-2">{'>'}</span>
                    <span className="text-accent/80">HIS: {point.neuronStates?.slice(0, 10).map(state => 
                      state.activation > 0.5 ? '[X]' : '[ ]'
                    ).join(' ')}</span>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Sandbox; 