import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Oops = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-lg mb-5">{t("widgets.components.oops.title")}</h1>

      <div className="my-5 text-center text-pretty">
        <p>{t("widgets.components.oops.text-line-1")}</p>
        <p>{t("widgets.components.oops.text-line-2")}</p>
      </div>

      <button
        className="rounded-xl m-auto py-2 px-6"
        onClick={() => window.location.reload()}>
        {t("widgets.components.oops.button")}
      </button>
    </div>
  )
}

export default Oops